import { graphql, PageProps } from 'gatsby'
import React from 'react'
import { Box } from '@mui/material'
import useMediaQueries from '../../hooks/useMediaQueries'
import BlogArticle from '../BlogArticle/BlogArticle'
import dotGrid from '../../images/icons/dot_grid.svg'
import Layout from '../Layout/Layout'
import SEO from '../SEO/SEO'
import rLogo from '../../images/icons/small_linear_r.svg'

// eslint-disable-next-line no-undef
const BlogPage = ({ data }: PageProps<Queries.BlogArticleQuery>) => {
  const { isSmall } = useMediaQueries()

  return (
    <>
      <SEO title='Blog' description={data?.sanityPost?.excerpt || undefined} />
      <Layout image={data?.sanityHomePage?.logo?.asset || undefined}>
        <img
          alt='logo'
          src={rLogo}
          style={{
            width: '40vw',
            position: 'absolute',
            right: '0px',
            zIndex: -1,
            top: '175px',
            maxHeight: '50vh',
            opacity: 0.6,
          }}
        />
        <Box
          maxWidth='1200px'
          position='relative'
          m='auto'
          sx={{
            '&::before': {
              content: '""',
              backgroundImage: `url('${dotGrid}')`,
              height: isSmall ? '0px' : '520px',
              width: '520px',
              position: 'absolute',
              top: 50,
              left: '-525px',
              zIndex: -1,
            },
          }}
          px={isSmall ? '8px' : '30px'}
          pt='100px'
        >
          <BlogArticle
            gatsbyImageData={
              data?.sanityPost?.mainImage?.asset?.gatsbyImageData
            }
            title={data.sanityPost?.title}
            category={
              data?.sanityPost?.categories &&
              data?.sanityPost?.categories.length > 0 &&
              data?.sanityPost?.categories[0]?.title
            }
            publishedAt={data.sanityPost?.publishedAt}
            author={data.sanityPost?.author?.name}
            // eslint-disable-next-line no-underscore-dangle
            blocks={data.sanityPost?._rawBody}
            tags={data.sanityPost?.tags}
            series={data.sanityPost?.series?.title}
            authorImage={data.sanityPost?.author?.image?.asset?.gatsbyImageData}
            // eslint-disable-next-line no-underscore-dangle
            authorBio={data?.sanityPost?.author?._rawBio}
            previousArticle={{
              title: data?.sanityPost?.prevArticle?.title,
              slug: data?.sanityPost?.prevArticle?.slug?.current,
            }}
            nextArticle={{
              title: data?.sanityPost?.nextArticle?.title,
              slug: data?.sanityPost?.nextArticle?.slug?.current,
            }}
          />
        </Box>
      </Layout>
    </>
  )
}

export default BlogPage

export const query = graphql`
  query BlogArticle($slug: String) {
    site {
      siteMetadata {
        title
      }
    }
    sanityHomePage {
      logo {
        asset {
          gatsbyImageData(width: 306, height: 100, placeholder: BLURRED)
        }
      }
    }
    sanityPost(slug: { current: { eq: $slug } }) {
      author {
        name
        _rawBio
        image {
          asset {
            gatsbyImageData(width: 300, height: 300, placeholder: BLURRED)
          }
        }
      }
      publishedAt(formatString: "MMMM Do, YYYY")
      tags {
        _id
        name
      }
      categories {
        title
      }
      mainImage {
        asset {
          gatsbyImageData(placeholder: BLURRED, height: 400)
          url
        }
      }
      excerpt
      title
      _rawBody(resolveReferences: { maxDepth: 10 })
      related
      series {
        id
        title
      }
      prevArticle {
        id
        title
        slug {
          current
        }
      }
      nextArticle {
        id
        slug {
          current
        }
        title
      }
    }
  }
`
