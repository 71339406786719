import React from 'react'
import { Card, CardContent, CardMedia, Typography } from '@mui/material'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { EmailOutlined } from '@mui/icons-material'
import BlockContent from '@sanity/block-content-to-react'
import useMediaQueries from '../../hooks/useMediaQueries'
import serializers from '../../serializers/serializers'

type AuthorCardProps = {
  name: string | undefined | null
  imageData: IGatsbyImageData | undefined
  bio: unknown
  email: string | undefined | null
}

const AuthorCard: React.FC<AuthorCardProps> = ({
  name,
  imageData,
  bio,
  email,
}) => {
  const { isSmall } = useMediaQueries()
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: isSmall ? 'column' : 'row',
        mt: 6,
        mb: '30px',
        mx: '30px',
        zIndex: 2,
      }}
    >
      <CardMedia
        sx={{
          textAlign: 'center',
          flex: '0 1 30%',
          px: isSmall ? 0 : '30px',
          py: isSmall ? 0 : '40px',
        }}
      >
        {imageData && (
          <GatsbyImage
            style={{
              borderRadius: isSmall ? 0 : '50%',
              background:
                '-webkit-linear-gradient(214deg, rgba(3, 191, 203, 0.57) -14.41%, rgba(30, 73, 123, 0.57) 114.41%), #03BFCB',
              boxShadow: `0px 0px 0px ${
                isSmall ? '3px' : '8px'
              } rgba(3, 191, 203, 0.2)`,
            }}
            image={imageData}
            alt=''
          />
        )}
      </CardMedia>
      <CardContent
        sx={{
          m: 'auto',
          flex: '1 0 70%',
          padding: '40px',
          paddingBottom: isSmall ? '60px' : '40px',
        }}
      >
        <Typography
          pt={2}
          gutterBottom
          component='div'
          textAlign='left'
          fontSize='1.49rem'
          lineHeight='1.33rem'
          color='#265790'
          textTransform='uppercase'
          fontWeight={700}
        >
          {name}
        </Typography>
        <BlockContent blocks={bio} serializers={serializers} />
        <Typography
          textTransform='uppercase'
          variant='caption'
          fontSize='14px'
          color='primary'
          display='flex'
          alignItems='center'
          pb={1}
        >
          <EmailOutlined
            sx={{
              color: 'var(--accent)',
              mr: 1,
            }}
          />
          <a href={`mailto:${email}`}>{email}</a>
        </Typography>
      </CardContent>
    </Card>
  )
}
export default AuthorCard
