import { Box, Button, Typography } from '@mui/material'
import axios from 'axios'
import { Field, FieldProps, Form, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import useHttpReducer from '../../hooks/useHttpReducer'
import useMediaQueries from '../../hooks/useMediaQueries'
import RDTextField from '../RDTextField/RDTextField'

type FormValues = {
  firstName: string
  email: string
  lastName: string
}

const SubscribeForm: React.FC = () => {
  const initVals: FormValues = {
    firstName: '',
    email: '',
    lastName: '',
  }
  const { dispatch, state } = useHttpReducer()

  const { theme } = useMediaQueries()

  return (
    <Formik
      initialValues={initVals}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required('Required.'),
        email: Yup.string()
          .email('Email not valid')
          .required('Email is required'),
        lastName: Yup.string(),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        dispatch({
          type: 'SENDING',
        })

        try {
          // Hit route for subscription
          await axios.post('/api/subscribe', values)

          dispatch({
            type: 'SUCCESS',
          })

          setSubmitting(false)
          resetForm()
        } catch {
          dispatch({
            type: 'ERROR',
          })
        }
      }}
    >
      <Form
        style={{
          paddingTop: '16px',
          maxWidth: '800px',
          width: '100%',
          textAlign: 'left',
        }}
      >
        {state.error && (
          <Typography py='16px' variant='subtitle2' color='error'>
            An error occurred.
          </Typography>
        )}
        {state.success && (
          <Typography
            variant='subtitle2'
            py='16px'
            color={theme.palette.success.main}
          >
            Success! Thanks for subscribing!
          </Typography>
        )}
        <Box display='flex'>
          <Field name='firstName'>
            {({
              field, // { name, value, onChange, onBlur }
              meta,
            }: FieldProps) => (
              <Box pb={2} flex='0 0 50%' pr={2}>
                <RDTextField
                  disabled={state.loading}
                  required
                  fullWidth
                  id='firstName'
                  label='First Name'
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={meta.touched && Boolean(meta.error)}
                  touched={meta.touched}
                  errorText={meta.touched && meta.error}
                />
              </Box>
            )}
          </Field>
          <Field name='lastName' flex='0 0 50%'>
            {({
              field, // { name, value, onChange, onBlur }
              meta,
            }: FieldProps) => (
              <Box pb={2}>
                <RDTextField
                  required
                  disabled={state.loading}
                  fullWidth
                  id='lastName'
                  label='Last Name'
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={meta.touched && Boolean(meta.error)}
                  touched={meta.touched}
                  errorText={meta.touched && meta.error}
                />
              </Box>
            )}
          </Field>
        </Box>
        <Field name='email'>
          {({
            field, // { name, value, onChange, onBlur }
            meta,
          }: FieldProps) => (
            <Box pb={2}>
              <RDTextField
                required
                fullWidth
                disabled={state.loading}
                id='email'
                label='E-mail Address'
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                touched={meta.touched}
                onBlur={field.onBlur}
                error={meta.touched && Boolean(meta.error)}
                errorText={meta.touched && meta.error}
              />
            </Box>
          )}
        </Field>
        <Button
          disabled={state.loading}
          variant='contained'
          color='secondary'
          sx={{
            px: 2,
            py: 2,
            mt: 1,
            borderRadius: '12px',
          }}
          type='submit'
        >
          Subscribe Now
        </Button>
      </Form>
    </Formik>
  )
}

export default SubscribeForm
