import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { Box, Button, Grid, styled, Typography, useTheme } from '@mui/material'
import { CalendarMonth, EmailOutlined } from '@mui/icons-material'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { Link, navigate } from 'gatsby'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import serializers from '../../serializers/serializers'
import useMediaQueries from '../../hooks/useMediaQueries'
import AuthorCard from '../AuthorCard/AuthorCard'
import SubscribeForm from '../SubscribeForm/SubscribeForm'

type BlogArticleProps = {
  series: string | null | undefined
  category: string | null | undefined | false
  title: string | null | undefined
  author: string | null | undefined
  authorImage: IGatsbyImageData | undefined
  authorBio: unknown | undefined
  blocks: unknown
  publishedAt: string | null | undefined
  tags:
    | readonly ({
        readonly _id: string | null
        readonly name: string | null
      } | null)[]
    | null
    | undefined
  gatsbyImageData: IGatsbyImageData | undefined
  previousArticle: {
    title: string | null | undefined
    slug: string | null | undefined
  }
  nextArticle: {
    title: string | null | undefined
    slug: string | null | undefined
  }
}

const GatsbyNextPrevLink = styled(Link)(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.primary.light,
  textDecorationColor: theme.palette.secondary.main,
}))

const BlogArticle: React.FC<BlogArticleProps> = ({
  series,
  category,
  title,
  author,
  blocks,
  publishedAt,
  tags,
  gatsbyImageData,
  authorBio,
  authorImage,
  previousArticle,
  nextArticle,
}) => {
  const theme = useTheme()
  const { isSmall } = useMediaQueries()
  return (
    <>
      <Box
        mb='5vh'
        p={isSmall ? 2 : 5}
        borderRadius='18px'
        sx={{
          background: 'white',
        }}
      >
        <Button
          startIcon={
            <KeyboardBackspaceIcon
              sx={{ color: theme.palette.secondary.main }}
            />
          }
          sx={{
            mb: 4,
          }}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        <figure
          style={{
            margin: 0,
            marginBottom: '80px',
            width: '100%',
            textAlign: 'center',
          }}
        >
          {gatsbyImageData && <GatsbyImage alt='' image={gatsbyImageData} />}
        </figure>
        <Typography
          variant='caption'
          bgcolor={theme.palette.secondary.dark}
          color='white'
          padding='8px'
          sx={{ textTransform: 'uppercase' }}
        >
          {series || category}
        </Typography>
        <Typography pt='18px' variant='h1' color='primary'>
          {title}
        </Typography>
        <Typography
          variant='body1'
          fontWeight={700}
          color='primary'
          pt={4}
          pb={1}
          style={{ textTransform: 'uppercase' }}
          className='label-big d-inline-block accent'
        >
          by {author}
        </Typography>
        <Typography
          textTransform='uppercase'
          variant='caption'
          fontSize='14px'
          color='primary'
          display='flex'
          alignItems='center'
          pb={1}
        >
          <CalendarMonth
            sx={{
              color: 'var(--accent)',
              mr: 1,
            }}
          />
          {publishedAt}
        </Typography>
        {tags && (
          <span className='label-small d-block'>
            <LocalOfferIcon
              sx={{
                color: 'var(--accent)',
                mr: 1,
              }}
            />{' '}
            {tags &&
              tags.map(tag => (
                <span style={{ color: 'var(highlight-color' }} key={tag?.name}>
                  {' '}
                  {tag?.name?.toLowerCase()}{' '}
                </span>
              ))}
          </span>
        )}
        <hr />
        {previousArticle?.title && previousArticle?.slug && (
          <Box py={3}>
            <GatsbyNextPrevLink to={`/blog/${previousArticle.slug}`}>
              Read Previous:{' '}
              <span style={{ fontWeight: 600 }}>{previousArticle?.title}</span>
            </GatsbyNextPrevLink>
          </Box>
        )}
        <article
          style={{
            paddingTop: '40px',
            paddingBottom: '64px',
            maxWidth: '680px',
            margin: 'auto',
          }}
          className='pt-3'
        >
          <BlockContent blocks={blocks} serializers={serializers} />
        </article>
        {nextArticle?.title && nextArticle?.slug && (
          <Box py={3}>
            <GatsbyNextPrevLink to={`/blog/${nextArticle.slug}`}>
              Read Next:{' '}
              <span style={{ fontWeight: 600 }}>{nextArticle?.title}</span>
            </GatsbyNextPrevLink>
          </Box>
        )}
        <AuthorCard
          name={author}
          email='jarrett@retz.dev'
          // bio=
          imageData={authorImage}
          bio={authorBio}
        />
      </Box>
      <Box
        mb='15vh'
        py='40px'
        sx={{
          background: 'white',
          borderRadius: '18px',
        }}
      >
        <Grid
          container
          columnSpacing={2}
          rowSpacing={2}
          justifyContent='center'
        >
          <Grid item sm={10} md={3}>
            <EmailOutlined
              sx={{
                fontSize: '225px',
                color: 'white',
                borderRadius: '18px',
                background: 'linear-gradient(45deg, #03BFCB 0%, #17304E 100%);',
              }}
            />{' '}
          </Grid>
          <Grid item sm={10} md={7}>
            <Typography
              color='#265790'
              textTransform='uppercase'
              fontWeight={700}
              fontSize='1.49rem'
              lineHeight='1.33rem'
            >
              Subscribe to get instant updates
            </Typography>
            <SubscribeForm />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default BlogArticle
